import Style from "./index.module.css";
import loading from "./loading.png";

function index() {
  return (
    <div className={Style["simple-page"]}>
      <img src={loading} alt="" width={"50px"} className={Style["logo"]} />
    </div>
  );
}

export default index;
