import "./App.less";
import { useRoutes } from "react-router-dom";
import routes from "@routers/index";

const App: React.FC = () => {
  let element = useRoutes(routes);
  return <>{element}</>;
};

export default App;
