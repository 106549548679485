const httpRequest = {
  get: (key: string) => {
    return window.localStorage.getItem(key);
  },

  set: (key: string, data: any) => {
    window.localStorage.setItem(key, data);
  },
  clear: () => {
    window.localStorage.clear();
  },
};

export default httpRequest;
