import { UPDATEUSER } from "../constant";
import db from "@utils/db";

const initPerson = JSON.parse(db.get("user") || "{}");

/**
 * Reducer必须是一个纯函数
 * 纯函数：一类特别的函数，只要是同样的输入（实参），必定得到同样的输出（返回）。
 * 故需遵守一下规则：
 *   （1）：不得改写参数数据
 *   （2）：不会产生任何副作用，例如网络请求，输入，输出设备。
 *   （3）：不能调用Date.now(),Math.random()等不纯的方法。
 *
 * @param {*} preState
 * @param {*} action
 * @returns
 */

export default function userReducer(preState = initPerson, action: any) {
  const { type, data } = action;
  switch (type) {
    case UPDATEUSER:
      db.set("user", JSON.stringify(data));
      return data;
    default:
      return preState;
  }
}
