import { Navigate, RouteObject } from "react-router-dom";
import { Suspense, lazy } from "react";
import Spin from "../components/Spin/index";
import AuthRoute from "../components/AuthRoute";

// const cur = () => {
//   return new Promise<any>((resolve) => {
//     setTimeout(() => {
//       resolve(import("../pages/login/index"));
//     }, 300);
//   });
// };

// const Login = lazy(async () => {
//   return await cur();
// });

const Layout = lazy(() => import("../pages/layout/index"));
const Home = lazy(() => import("../pages/Home/index"));
const File = lazy(() => import("../pages/File/index"));
const Login = lazy(() => import("../pages/login/index"));
const User = lazy(() => import("../pages/User/index"));
const Dept = lazy(() => import("../pages/Dept/index"));

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to={"/sys/home"}></Navigate>,
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<Spin />}>
        <Login></Login>
      </Suspense>
    ),
  },
  {
    path: "/sys",
    element: (
      <Suspense fallback={<Spin />}>
        <AuthRoute>
          <Layout></Layout>
        </AuthRoute>
      </Suspense>
    ),
    children: [
      {
        path: "home",
        element: (
          <Suspense fallback={<Spin />}>
            <Home></Home>
          </Suspense>
        ),
      },
      {
        path: "file",
        element: (
          <Suspense fallback={<Spin />}>
            <File></File>
          </Suspense>
        ),
      },
      {
        path: "user",
        element: (
          <Suspense fallback={<Spin />}>
            <User></User>
          </Suspense>
        ),
      },
      {
        path: "dept",
        element: (
          <Suspense fallback={<Spin />}>
            <Dept></Dept>
          </Suspense>
        ),
      },
    ],
  },
];

export default routes;
